import { makeStyles, Theme } from '@material-ui/core';

export const useSideStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '0',
      zIndex:9999
    },
  },
  downloadSide: {
    width: '100%',
    height: '10rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '10rem',
    paddingRight: '10rem',
    borderTop: '0.4px white solid',
    borderBottom: '0.4px white solid',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      color: '#000',
      background: '#68FE60',
    },
    '&:hover img': {
      filter: 'invert(100%)',
    },
    [theme.breakpoints.down('sm')]: {
      height: '6rem',
      paddingLeft: '2.4rem',
      paddingRight: '2.4rem',
    },
  },
  title: {
    fontSize: '2.4rem',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    gap: '4rem',
    '& a': {
      fontSize: '1.4rem',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '2.1rem',
      wordWrap: 'break-word',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    '& img': {
      width: '3.2rem',
      height: '3.2rem',
      marginRight: '.8rem',
      [theme.breakpoints.down('sm')]: {
        width: '1.6rem',
        height: '1.6rem',
        marginRight: '.4rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      gap: '.8rem',
    },
  },
  arrow: {
    width: '3.2rem',
    height: '3.2rem',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '2rem',
      height: '2rem',
    },
  },
  linkSide: {
    overflow:'hidden',
    width: '100%',
    height: '10rem',
    borderTop: '0.4px white solid',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      height: '6rem',
    },
  },
  line: {
    height: '100%',
    backgroundColor: 'white',
    width: '0.4px',
  },
  link: {
    width: '50%',
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    padding: '3.4rem 10rem',
    '&:hover': {
      cursor: 'pointer',
      color: '#000',
      background: '#68FE60',
    },
    '&:hover img': {
      filter: 'invert(100%)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      padding: '1.2rem 2.4rem',
    },
  }
}));
