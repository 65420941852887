import classNames from 'classnames';
import { useNavStyle } from './useNavStyle';
import { isPc, heightToTop, moveScroll } from 'assets/js/tool';
import { t } from 'modules/i18n/utils/intl';
interface NavProps {
  isHide: boolean;
  navClick: any;
}

let isNav = 0;

export const Nav = ({ isHide, navClick }: NavProps) => {
  const classes = useNavStyle();
  const data = [
    {
      name: t('home.home'), url: '#page1',
    },
    {
      name: t('home.feature'), url: '#page2',
    },
    {
      name: t('home.gameplay'), url: '#page3',
    },
    {
      name: t('home.nFT'), url: '#page4',
    },
    {
      name: t('home.feedback'), url: '#page6',
    },
    {
      name: t('home.dashboard'), url: 'https://app.archloot.com',
    },
    {
      name: t('home.vote'), url: 'https://vote.archloot.com/',
    },
    {
      name: t('home.forum'), url: 'https://forum.archloot.com/',
    },
    {
      name: t('home.update'), url: '#page5',
    },
    {
      name: 'Bridge', url: process.env.REACT_APP_BASE_ENV!=='production' ? 'https://narchloot.test.connector.games/bridge' : 'https://archloot.com/bridge',
    },
  ];
  const hideClick = (e: any, i: number, id: string) => {
    id = id.replace('#', '');
    const node = document.getElementById(id);

    if (!node) return;

    e.preventDefault();
    isNav = i;
    navClick(isHide = true);
    moveScroll();

    const side = document.getElementById('side');
    const sideH = side ? side.offsetHeight : 0;
    window.scrollTo({
      top: heightToTop(node) - (isPc ? 0 : sideH),
      behavior: 'smooth',
    });
  };

  return (
    <div className={classes.root}>
      {
        data.map((d, i) => {
          return <a key={i} className={classNames(classes.link, isNav === i && 'active')}
                    href={d.url} target='_blank'
                    onClick={(e) =>
                      hideClick(e, i, d.url)} rel='noreferrer'>{d.name}</a>;
        })
      }
    </div>
  );
};
