import { makeStyles, Theme } from '@material-ui/core';

export const usePageSideStyle = makeStyles((theme: Theme) => ({
  root: {
    pointerEvents: 'auto',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '10rem',
    height: '100%',
    borderRight: '0.4px solid #FFF',
    backdropFilter: 'blur(4px)',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '6rem',
      borderRight: 'none',
      borderBottom: '0.4px solid #FFF',
    },
  },
  wrap: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  btnBox: {
    position: 'relative',
    width: '10rem',
    height: '10rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      height: '6rem',
    },
    '&:hover': {
      '& .hvrShow': {
        display: 'block',
      },
      '& .hvrHide': {
        display: 'none',
      }
    },
  },
  icon0: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    width: '10rem',
    height: '10rem',
    padding: '3.4rem',
    borderBottom: '0.4px solid #FFF',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      height: '6rem',
      padding: '1.6rem',
      borderBottom: 'none',
      borderRight: '0.4px solid #FFF',
    },
  },
  icon1: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    width: '10rem',
    height: '10rem',
    padding: '3.4rem',
    borderBottom: '0.4px solid #FFF',
    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      height: '6rem',
      padding: '1.6rem',
      borderBottom: 'none',
      borderRight: '0.4px solid #FFF',
    },
  },
  logo: {
    position: 'absolute',
    top: '0',
    width: '10rem',
    height: '10rem',
    padding: '2.5rem',
    borderBottom: '0.4px solid #FFF',
    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      height: '6rem',
      padding: '1.6rem',
      borderBottom: 'none',
      borderRight: '0.4px solid #FFF',
    },
  },
  name: {
    width: '2rem',
    height: '18.4247rem',
    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      height: '11.0548rem',
      transform: 'rotate(-90deg) translate(5.5rem, 7.75rem)',
    },
  },
  play: {
    color: '#68FE60',
    fontSize: 20,
    fontFamily: 'Poppins',
    fontWeight: 400,
    textTransform: 'uppercase',
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    transform: 'rotate(90deg) translate(-100%,-100%)',
    transformOrigin: 'left center',
    position: 'absolute',
    bottom: '2.4rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
