import classNames from 'classnames';
import { useLinkTxtStyle } from './useLinkTxtStyle';
import down from 'assets/img/icon/down.png';
import { t } from 'modules/i18n/utils/intl';
import { Locale } from 'modules/i18n/types/locale';
import { setLocale } from 'modules/i18n/i18nSlice';
import { useLocaleMemo } from 'modules/i18n/utils/useLocaleMemo';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { useAppDispatch } from 'store/useAppDispatch';
import { IconLink } from 'assets/js/iconLink';

export const LinkTxt = () => {
  const { locale } = useLocale();
  const dispatch = useAppDispatch();
  const list = [
    {
      name: 'FAQ', url: IconLink.faq,
    },
    {
      name: t('home.instruction'),
      url: IconLink.instruction,
    },
    {
      name: t('home.blog'), url: IconLink.medium,
    },
    {
      name: t('home.whitepaper'), url: IconLink.docs,
    },
    {
      name: t('home.contact'), url: IconLink.us,
    },
    {
      name: t('home.mediaKit'), url: IconLink.mediaKit,
    },
  ];
  const localeOptions = useLocaleMemo(
    () => [
      {
        value: Locale.en,
        label: t('language.en-US'),
      },
      {
        value: Locale.ja,
        label: t('language.ja-JP'),
      },
    ],
    [],
  );
  const onChange = (value: string) => {
    dispatch(setLocale(value as Locale));
  };
  const classes = useLinkTxtStyle();
  return (
    <div className={classes.root}>
      {
        list.map(d => {
          return <a className={classes.link} key={d.name} href={d.url}>{d.name}</a>;
        })
      }
      <div className={classes.language}>
        <div className='w-all col-in row-out'>
          Language
          <img className={classNames(classes.down, 'down')} src={down} alt='' />
        </div>
        <div className={classNames(classes.pop, 'pop w-all')}>
          {/*<a className='w-all' href='http://'>Japanese</a>*/}
          {
            localeOptions.map(d => {
              return (
                <div onClick={() => onChange(d.value)}
                     key={d.value}
                     className={classNames('w-all',
                       locale === d.value ? 'activeLanguage' : '')}
                >{d.label}
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};
