import classNames from 'classnames';
import { usePageSideStyle } from './usePageSideStyle';
import logo from 'assets/img/side/logo.png';
import name from 'assets/img/side/name.png';
import { stopScroll, moveScroll } from 'assets/js/tool';
import { t } from 'modules/i18n/utils/intl';

interface PageSideProps {
  isHide: boolean;
  navClick: any;
}

export const PageSide = ({ isHide, navClick }: PageSideProps) => {
  const classes = usePageSideStyle();

  const showClick = () => {
    navClick(isHide = false);
    stopScroll();
  };
  const hideClick = () => {
    navClick(isHide = true);
    moveScroll();
  };

  return (
    <div id='side' className={classes.root}>
      <div className={classes.wrap}>
        <div onClick={showClick} className={classNames(classes.btnBox, !isHide && 'hide')}>
          <svg className={classNames(classes.icon0, 'hvrShow')} xmlns='http://www.w3.org/2000/svg' width='32'
               height='32' viewBox='0 0 32 32' fill='none'>
            <line y1='15.5' x2='32' y2='15.5' stroke='white' />
            <line y1='3.5' x2='16' y2='3.5' stroke='white' />
            <line y1='27.5' x2='24' y2='27.5' stroke='white' />
          </svg>
          <img className={classNames(classes.logo, 'hvrHide')} src={logo} alt='' />
        </div>
        <svg onClick={hideClick} className={classNames(classes.icon1, isHide && 'hide')}
             xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
          <path d='M31.6215 8.37883L8.37988 31.6205' stroke='#F5F5F5' strokeWidth='1.5' />
          <path d='M8.37891 8.37883L31.6206 31.6205' stroke='#F5F5F5' strokeWidth='1.5' />
        </svg>
        <img className={classNames(classes.name, 'fit-abs5')} src={name} alt='' />
        <div className={classes.play}>{t('home.playNow')}</div>
      </div>
    </div>
  );
};
