import { useSideStyle } from './useSideStyle';
import classNames from 'classnames';
import iOS from 'assets/img/icon/iOS.png';
import APK from 'assets/img/icon/APK.png';
import Android from 'assets/img/icon/Android.png';

import arrow from 'assets/img/icon/arrow.png';
// import { MintRoutesConfig } from 'modules/Mint/Routes';
// import { PassRoutesConfig } from 'modules/Swap/Routes';
import { t } from 'modules/i18n/utils/intl';
import { IconLink } from '../../../../../../assets/js/iconLink';
import { useHistory } from 'react-router-dom';
import {SwapRoutesConfig} from '../../../../../Swap/Routes';

export const Side = () => {
  const classes = useSideStyle();
  const history = useHistory();
  return (
    <div className={classNames(classes.root, 'sideBox')}>
      <div className={classNames(classes.linkSide, 'linkSide row-out col-in')}>
        <div className={classNames(classes.link, 'row-out col-in')} onClick={() => {
          history.push(SwapRoutesConfig.Index.path)
        }}>
          Bridge
          <img className={classes.arrow} src={arrow} alt='right' />
        </div>
        <div className={classNames(classes.line)} />
        <div className={classNames(classes.link, 'row-out col-in')} onClick={() => {
          window.open('https://app.archloot.com/mint')
        }}>
          {t('home.goBuyPass')}
          <img className={classes.arrow} src={arrow} alt='right' />
        </div>
        <div className={classNames(classes.line)} />
        <div className={classNames(classes.link, 'row-out col-in')} onClick={() => {
          window.open('https://app.archloot.com/pass')
        }}>
          {t('home.adventurerPass')}
          <img className={classes.arrow} src={arrow} alt='right' />
        </div>
      </div>
      <div className={classNames(classes.downloadSide, 'downloadSide')}>
        <div className={classNames(classes.title)}>{t('home.downloadForFree')}</div>
        <div className={classNames(classes.download)}>
          <a className='flex-in' href={IconLink.ios} target='_self'
             rel='noreferrer'>
            <img src={iOS} alt='' />
            iOS
          </a>
          <a className='flex-in' href={IconLink.archLootApk} target='_self' rel='noreferrer'>
            <img src={APK} alt='' />
            APK
          </a>
          <a className='flex-in' href={IconLink.android}
             target='_self' rel='noreferrer'>
            <img src={Android} alt='' />
            Android
          </a>
        </div>
      </div>
    </div>
  );
};
